import React, { Fragment, useRef, useState } from "react";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  IMAGE_FILE_TYPES,
  MAXIMUM_FILE_SIZE_LIMIT,
  MAXIMUM_FILE_UPLOAD_LIMIT,
  MESSAGES,
  MINIMUM_FILE_UPLOAD_LIMIT,
  FILE_UPLOAD_TYPE,
} from "../../constants";
import COMMON_ACTIONS from "../../store/common/actions";
import { processImage, uploadImage } from "../../utils/imageHelper";
import dropImageStyles from "./style.module.css";
import { toast } from "react-toastify";

const styles = {
  preview: {
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%" },
  delete: {
    cursor: "pointer",
    color: "white",
    border: "none",
  },
};

const AppPropDropImage = (props) => {
  const dispatch = useDispatch();

  const { selectedImage, images } = useSelector((state) => state.common);

  const dragItem = useRef();
  const dragOverItem = useRef();
  const [addImage, setAddImage] = useState(true);

  const handleImageChange = async (event) => {
    const { files } = event.target;

    // const processedImages = await processImage(files[0]);

    // if (!IMAGE_FILE_TYPES.includes(processedImages.file.type)) {
    //   props.formik.setFieldError("images", MESSAGES.VALID_IMAGE_FILE_TYPE);
    //   return false;
    // } else if (processedImages.file.size > MAXIMUM_FILE_SIZE_LIMIT) {
    //   props.formik.setFieldError("images", MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
    //   return false;
    // } else if (processedImages.is4by3 === false) {
    //   props.formik.setFieldError("images", MESSAGES.FILE_UPLOAD_ASPECT_RATIO);
    //   return false;
    // } else {
    //   props.formik.setFieldError("images", "");
    // }

    // const formData = new FormData();
    // formData.append("file", processedImages?.file);
    // formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);
    // uploadImage(formData)
    //   .then((res) => {
    //     console.log(res, " 558885588 ");
    //     let selectedImageCopy = selectedImage ? selectedImage : [];
    //     let imagesCopy = images ? images : [];
    //     dispatch({
    //       type: COMMON_ACTIONS.SET_IMAGES,
    //       key: "selectedImage",
    //       value: [...selectedImageCopy, res?.data],
    //     });
    //     dispatch({
    //       type: COMMON_ACTIONS.SET_IMAGES,
    //       key: "images",
    //       value: [...imagesCopy, res?.data?.fileId],
    //     });
    //   })
    //   .catch((error) => {
    //     return toast.error(error?.data?.message || "something went wrong!!");
    //   });

    // if (selectedImage?.length <= MINIMUM_FILE_UPLOAD_LIMIT) {
    //   props.formik.setFieldError("images", MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
    // } else if (selectedImage?.length > MAXIMUM_FILE_UPLOAD_LIMIT) {
    //   props.formik.setFieldError("images", MESSAGES.MAXIMUM_UPLOAD_FILE_LIMIT);
    // } else {
    //   props.formik.setFieldError("images", "");
    // }

    // console.log(selectedImageCopy, " dfjghjkdfhkjdfhghfd \n", imagesCopy, " 123out")

    // console.log(" hello image")
    if (files && files?.length) {
      const fileImages = [];

      for (let i = 0; i < files?.length; i++) {
        const result = processImage(files[i]);
        fileImages.push(result);
      }

      const processedImages = await Promise.all(fileImages);
      const invalidFileTypes = processedImages?.filter(
        (item) => !IMAGE_FILE_TYPES.includes(item.file.type)
      );
      const invalidNewFileSize = processedImages?.filter(
        (item) => item.file.size > MAXIMUM_FILE_SIZE_LIMIT
      );
      const invalidOldFileSize = images?.filter(
        (item) => item.size > MAXIMUM_FILE_SIZE_LIMIT
      );
      const invalidAspectRatio = processedImages?.filter(
        (item) => item.is4by3 === false
      );

      if (
        images?.length + processedImages?.length <
        MINIMUM_FILE_UPLOAD_LIMIT
      ) {
        props.formik.setFieldError(
          "images",
          MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT
        );
      } else if (
        images?.length + processedImages?.length >
        MAXIMUM_FILE_UPLOAD_LIMIT
      ) {
        props.formik.setFieldError(
          "images",
          MESSAGES.MAXIMUM_UPLOAD_FILE_LIMIT
        );
      } else if (invalidFileTypes?.length) {
        props.formik.setFieldError("images", MESSAGES.VALID_IMAGE_FILE_TYPE);
      } else if (invalidNewFileSize?.length || invalidOldFileSize?.length) {
        props.formik.setFieldError("images", MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
      } else if (invalidAspectRatio?.length) {
        props.formik.setFieldError("images", MESSAGES.FILE_UPLOAD_ASPECT_RATIO);
      } else {
        props.formik.setFieldError("images", "");
      }

      const uploadPromises = processedImages?.map(async (item) => {
        const formData = new FormData();
        formData.append("file", item?.file);
        formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);

        const res = await uploadImage(formData);
        return res?.data;
      });

      const uploadedImages = await Promise.all(uploadPromises);

      let selectedImageCopy = selectedImage ? selectedImage : [];
      let imagesCopy = images ? images : [];
      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "selectedImage",
        value: [...selectedImageCopy, ...uploadedImages],
      });
      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "images",
        value: [...imagesCopy, ...uploadedImages],
      });
    }
  };

  const removeSpecificImage = (index) => {
    const tempSelectedImage = [...selectedImage];
    tempSelectedImage.splice(index, 1);

    const tempImages = [...images];
    tempImages.splice(index, 1);

    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "selectedImage",
      value: tempSelectedImage,
    });
    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "images",
      value: tempSelectedImage,
    });

    const invalidFileSize = tempImages.filter(
      (item) => item.size && item.size > MAXIMUM_FILE_SIZE_LIMIT
    );
    const invalidFileTypes = tempImages.filter(
      (item) => item.type && !IMAGE_FILE_TYPES.includes(item.type)
    );

    if (tempImages?.length < 3) {
      props.formik.setFieldError("images", MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
    } else if (invalidFileSize?.length) {
      props.formik.setFieldError(
        "images",
        "Please upload maximum file size of 3MB."
      );
    } else if (invalidFileTypes?.length) {
      props.formik.setFieldError("images", MESSAGES.VALID_IMAGE_FILE_TYPE);
    } else {
      props.formik.setFieldError("images", "");
    }
  };

  const removeAllImages = () => {
    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "selectedImage",
      value: [],
    });
    dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: [] });

    props.formik.setFieldError("images", MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
  };

  const dragStart = (event, position) => {
    dragItem.current = position;
  };

  const dragEnter = (event, position) => {
    dragOverItem.current = position;
  };

  const dropImage = () => {
    const copiedSelectedImages = [...selectedImage];
    const copyImages = [...images];

    const dragItemContent = copiedSelectedImages[dragItem.current];

    copiedSelectedImages.splice(dragItem.current, 1);
    copyImages.splice(dragItem.current, 1);
    copiedSelectedImages.splice(dragOverItem.current, 0, dragItemContent);
    copyImages.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "selectedImage",
      value: copiedSelectedImages,
    });
    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "images",
      value: copyImages,
    });
  };
  return (
    <Fragment>
      <label className="mb-2">Add Image</label>
      <div className="drop-image-outer" style={styles.container}>
        <Field
          name="images"
          placeholder=""
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="show-for-sr"
          multiple
        />
        <span>
          <i className="fas fa-images"></i>
        </span>
      </div>

      {selectedImage?.length !== 0 && (
        <div className={dropImageStyles.removeAll}>
          <button onClick={removeAllImages} style={styles.delete}>
            <p className={dropImageStyles["remove-images"]}>Remove All</p>
          </button>
        </div>
      )}

      <div
        className={`${dropImageStyles["image-pre-outer"]} ${
          selectedImage?.length ? dropImageStyles["selected-images"] : null
        }`}
      >
        {selectedImage?.map((item, index) => (
          <div
            key={index}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnd={dropImage}
            onDragEnter={(e) => dragEnter(e, index)}
            className={dropImageStyles["main-img-priew"]}
            draggable
            style={styles.preview}
          >
            <img
              name="hero"
              src={item?.fileUrl}
              style={styles.image}
              alt="Thumb"
            />
            <p onClick={() => removeSpecificImage(index)}>X</p>
          </div>
        ))}
      </div>
      {props.formik.touched.images && props.formik.errors.images ? (
        <div className="error mt-2">{props.formik.errors.images}</div>
      ) : null}
    </Fragment>
  );
};

export default AppPropDropImage;
