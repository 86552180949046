import React from 'react'
import star from "../../../../assets/images/star.png"
import heart from "../../../../assets/images/heart.png"
import share from "../../../../assets/images/share.png"
import galone from "../../../../assets/images/galone.png"
import galtwo from "../../../../assets/images/galtwo.png"
import galthree from "../../../../assets/images/galthree.png"
import galfour from "../../../../assets/images/galfour.png"
import galfive from "../../../../assets/images/galfive.png"
import maping from "../../../../assets/images/maping.png"
import bd from "../../../../assets/images/bd.png"
import lclc from "../../../../assets/images/lclc.png"
import styles from "./style.module.css";

function PropertyView() {
  return (
    <div className='propertViewOuter'>
        <div className={styles.ProertNames}>
            <h6>RESIDENTIAL</h6>
            <h2>1,353 sq ft Shop in Bahrain for rent, apply asap <span>BHD 120<label>/night</label></span></h2>
            <p><img src={lclc} alt=""/>Abdullah Bin Abbas Avenue Manama</p>
            <div className={styles.sghreProp}>
            <h5><img src={star} alt=""/> (4.5) <span><b>Very Good </b>    371 reviews</span></h5>
            <ul>
                <li><span><img src={heart} alt=""/></span></li>
                <li><span><img src={share} alt=""/></span></li>
            </ul>
            </div>
            
        </div>
        <div className={styles.galleryImages}>
            <div className='row m-0'>
                <div className='col-md-6 p-0'>
                    <div className={styles.galleryLewft}>
                        <img src={galone} alt=""/>
                    </div>
                </div>
                <div className='col-md-6 p-0'>
                    <div className={styles.galleryRight}>
                       <div className={styles.glimg}>
                       <img src={galtwo} alt=""/>
                       </div>
                       <div className={styles.glimg}>
                       <img src={galthree} alt=""/>
                       </div>
                       <div className={styles.glimg}>
                       <img src={galfour} alt=""/>
                       </div>
                       <div className={styles.glimg}>
                       <img src={galfive} alt=""/>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.PropDesc}>
            <h3>Overview</h3>
            <p>An entire apartment offering with  one bedroom, basic kitchen, living room and private balcony . This apartment is provided with smart television along with other amenities like fridge, oven, Water purifier. Beautiful apartment covered with pine trees around. Market place is only 5 mins away. The View is magical from the balcony as well as from the rooftop.</p>

        </div>
        <div className={styles.bedRooms}>
            <ul>
                <li><span><img src={bd} alt=""/> 2 Beds</span></li>
                <li><span><img src={bd} alt=""/> 2 Baths</span></li>
                <li><span><img src={bd} alt=""/> 1,200 sq ft</span></li>
            </ul>
        </div>
        <div className={styles.ProDetails}>
            <h3>Property details</h3>
            <ul>
                <li><span>Type</span>
                    <label>Residential</label>
                </li>
                <li><span>Property Type</span>
                    <label>Villa</label>
                </li>
                <li><span>Number of parking</span>
                    <label>2</label>
                </li>
                <li><span>Floor number</span>
                    <label>2</label>
                </li>
                <li><span>State</span>
                    <label>Completely Refurbished</label>
                </li>
                <li><span>Year of Construction</span>
                    <label>2012</label>
                </li>
                <li><span>Building floors</span>
                    <label>2</label>
                </li>
                <li><span>Number of balconies</span>
                    <label>2</label>
                </li>
            </ul>
        </div>
        <div className={styles.aminities}>
            <h3>Amenities</h3>
            <ul>
                <li><img src={bd} alt=""/> Outdoor pool</li>
                <li><img src={bd} alt=""/> Fitness center</li>
                <li><img src={bd} alt=""/> Restaurant</li>
                <li><img src={bd} alt=""/> Indoor pool</li>
                <li><img src={bd} alt=""/> Bar/Lounge</li>
                <li><img src={bd} alt=""/> Room service</li>
                <li><img src={bd} alt=""/> Spa and wellness center</li>
                <li><img src={bd} alt=""/> Free Wi-Fi</li>
                <li><img src={bd} alt=""/> Coffee machine</li>
            </ul>
        </div>
        <div className={styles.furnishing}>
            <h3>Furnisihng</h3>
            <h5>Fully Furnished</h5>
            <p>Furnished bathroom, Two Almirah, LED TV, Cuttons, Telephone</p>
        </div>
        <div className={styles.landmark}>
            <h3>Landmark (Nearby location)</h3>
            <ul>
                <li><span>Hospital</span></li>
                <li><span>Restaurant</span></li>
            </ul>
        </div>
        <div className={styles.addlocation}>
            <h3>Address / Location <span>View on google maps</span></h3>
            <img src={maping} alt="" />
        </div>
    </div>
  )
}

export default PropertyView
