import {
  useFormik,
  FieldArray,
  Field,
  ErrorMessage,
  FormikProvider,
} from "formik";
import React, { useEffect, Fragment, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import Switch from "react-switch";

import { VendorPropertyAction } from "../../../../../store/vendor/thunk";
import { AdminPropertyAction } from "../../../../../store/admin/thunk";
import {
  EDIT_PROPERTY_TYPE,
  FILE_UPLOAD_TYPE,
  IMAGE_FILE_TYPES,
  MAXIMUM_FILE_SIZE_LIMIT,
  MESSAGES,
  MINIMUM_FILE_UPLOAD_LIMIT,
  TOAST_TYPE,
  USER_TYPE,
  EDITOR_PLUGINS,
} from "../../../../../constants";
import { processImage, uploadImage } from "../../../../../utils/imageHelper";
import { Toaster, DropImage } from "../../../../../components";
import {
  editAmenitiesSchema,
  editLocationSchema,
  editNameSchema,
  editPropertyFeatureSchema,
} from "../../../../../schemas/editPropertySchema";
import styles from "./style.module.css";
import COMMON_ACTIONS from "../../../../../store/common/actions";
import { CommonAction } from "../../../../../store/common/thunk";

const EditProperty = (props) => {
  const dispatch = useDispatch();
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedBathroom, setSelectedBathroom] = useState({});
  const [recommendedProperties, setRecommendedProperties] = useState({
    properties: [],
    count: 0,
  });

  const {
    bedCategories,
    propertyAmenities,
    images,
    user,
    recommendedPropertiesPagination,
  } = useSelector((state) => state.common);
  const observerTarget = useRef(null);

  const editNameFormik = useFormik({
    initialValues: { name: "", unitNumber: "" },
    enableReinitialize: true,
    validationSchema: editNameSchema,
    onSubmit: (values) => {
      props.update(
        { ...values, property_id: props.property._id },
        props.loader,
        props.property._id
      );

      props.closeEditPropertyModal();
    },
  });

  const editLocationFormik = useFormik({
    initialValues: {
      building: "",
      area: "",
      city: "",
    },
    enableReinitialize: true,
    validationSchema: editLocationSchema,
    onSubmit: (values) => {
      props.update(
        {
          city_id: values.city,
          areaId: values.area,
          buildingId: values.building,
          property_id: props.property._id,
        },
        props.loader,
        props.property._id
      );

      props.closeEditPropertyModal();
    },
  });

  const editDescriptionFormik = useFormik({
    initialValues: { description: "" },
    enableReinitialize: true,
    onSubmit: (values) => {
      props.update(
        { ...values, property_id: props.property._id },
        props.loader,
        props.property._id
      );
      props.closeEditPropertyModal();
    },
  });

  const editAmnitiesFormik = useFormik({
    initialValues: { amenities: [] },
    enableReinitialize: true,
    validationSchema: editAmenitiesSchema,
    onSubmit: (values) => {
      const amenities = values.amenities
        .filter((item) => item.checked)
        .map((item) => ({
          _id: item.value,
          isSpecial: item.special ? item.special : false,
        }));
      props.update(
        { amenities, property_id: props.property._id },
        props.loader,
        props.property._id
      );
      props.closeEditPropertyModal();
    },
  });

  const propertyFeaturesFormik = useFormik({
    initialValues: { bathrooms: [], beds: [], totalBathrooms: 0, totalBeds: 0 },
    validationSchema: editPropertyFeatureSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let bedrooms = [];
      let bathRooms = [];

      for (let bed of values.beds) {
        const items = bed.items.map((item) => ({
          numberOfBeds: parseInt(item.totalBeds, 10),
          bedType: item.bedType,
          bedRoomNumber: bed.bedRoomName,
        }));
        bedrooms.push(...items);
      }

      for (let bathroom of values.bathrooms) {
        let obj = {};

        if (bathroom.private === "true") {
          obj.bathRoomPrivate = true;
          obj.bathRoomEnsuite = false;
          obj.insideAppartment = false;
        } else {
          obj.bathRoomPrivate = false;
        }

        if (bathroom.ensuite === "true") {
          obj.bathRoomEnsuite = true;
        }
        // else {
        //     obj.insideAppartment = false;
        //     obj.bathRoomEnsuite = false;
        //     delete obj.insideAppartment;
        // };
        if (bathroom.insideBathroom === "true") obj.insideAppartment = true;

        if (bathroom.bedroom) {
          obj.bedRoomNumber = bathroom.bedroom;
          delete obj.insideAppartment;
        }

        bathRooms.push(obj);
      }

      props.update(
        { bedrooms, bathRooms, property_id: props.property._id },
        props.loader,
        props.property._id
      );
      props.closeEditPropertyModal();
    },
  });

  const handleValidate = async () => {
    const errors = {};
    let fileImages = [];

    for (let i = 0; i < images.length; i++) {
      if (images[i] instanceof File) {
        const result = processImage(images[i]);
        fileImages.push(result);
      }
    }

    const processedImages = await Promise.all(fileImages);
    const invalidAspectRatio = processedImages.filter(
      (item) => item.is4by3 === false
    );
    const invalidFileTypes = processedImages.filter(
      (item) => !IMAGE_FILE_TYPES.includes(item.file.type)
    );
    const invalidOldFileSize = images.filter(
      (item) => item.size > MAXIMUM_FILE_SIZE_LIMIT
    );
    const invalidNewFileSize = processedImages.filter(
      (item) => item.file.size > MAXIMUM_FILE_SIZE_LIMIT
    );

    if (images.length < MINIMUM_FILE_UPLOAD_LIMIT) {
      errors.images = MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT;
    } else if (invalidAspectRatio.length) {
      errors.images = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
    } else if (invalidFileTypes.length) {
      errors.images = MESSAGES.VALID_IMAGE_FILE_TYPE;
    } else if (invalidNewFileSize.length || invalidOldFileSize.length) {
      errors.images = MESSAGES.UPLOAD_FILE_SIZE_LIMIT;
    }

    return errors;
  };

  const editImageFormik = useFormik({
    initialValues: { images: [] },
    validate: handleValidate,
    onSubmit: async () => {
      let uploadedImages = await Promise.all(
        images
          ?.filter((val) => typeof val !== "string")
          .map((val) => {
            const formData = new FormData();
            formData.append("file", val);
            formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);
            return uploadImage(formData);
          })
      );

      let fileError = uploadedImages.find(
        (item) =>
          item && item.status === false && item.error === "File too large"
      );

      if (fileError) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
        return;
      }

      uploadedImages = uploadedImages
        .filter((item) => item !== undefined)
        .map((val) => val.data.fileUrl);
      uploadedImages = [
        ...uploadedImages,
        ...images
          .filter((item) => typeof item === "string" && item.startsWith("http"))
          .map((val) => {
            let splitValue = val.split("/");
            return splitValue[4];
          }),
      ];

      props.update(
        { images: uploadedImages, property_id: props.property._id },
        props.loader,
        props.property._id
      );
      props.closeEditPropertyModal();
    },
  });

  const editOtherRoomFormik = useFormik({
    initialValues: {
      maidRoom: false,
      maidRoomBathRoom: false,
      driverRoom: false,
      driverRoomBathRoom: false,
    },
    onSubmit: (values) => {
      props.update(
        { otherRoom: values, property_id: props.property._id },
        props.loader,
        props.property._id
      );
      props.closeEditPropertyModal();
    },
    enableReinitialize: true,
  });

  const setPropertyInputs = (reloadAPI = true) => {
    editNameFormik.setValues({
      name: props.property.name,
      unitNumber: props.property.unitNumber,
    });

    const cities = props.property.cityData.map((item) => ({
      isSelected: item.isSelected,
      name: item.name,
      status: item.status,
      _id: item._id,
    }));
    const selectedCity = props.property.cityData.find(
      (item) => item.isSelected
    );

    const areas = selectedCity.areaData.map((item) => ({
      isSelected: item.isSelected,
      name: item.name,
      status: item.status,
      _id: item._id,
      buildings: item.buildingData,
    }));
    const selectedArea = selectedCity.areaData.find((item) => item.isSelected);

    const buildings = selectedArea.buildingData.map((item) => ({
      isSelected: item.isSelected,
      name: item.name,
      status: item.status,
      _id: item._id,
    }));
    const selectedBuilding = buildings.find((item) => item.isSelected);

    editLocationFormik.setValues({
      city: selectedCity._id,
      building: selectedBuilding._id,
      area: selectedArea._id,
    });

    setCities(cities);
    setAreas(areas);
    setBuildings(buildings);

    editDescriptionFormik.setValues({
      description: props.property.description,
    });

    const beds = props.property.bedRoomData.map((item) => ({
      bedRoomName: item.bedRoomNumber,
      items: item.bedData.map((innerItem) => ({
        bedType: innerItem.bedType,
        totalBeds: innerItem.numberOfBeds,
      })),
    }));

    let selectedBathrooms = {};

    const bathrooms = props.property.bathRooms.map((item) => {
      let obj = {
        private: item.bathRoomPrivate
          ? item.bathRoomPrivate.toString()
          : "false",
        ensuite: item.bathRoomEnsuite
          ? item.bathRoomEnsuite.toString()
          : "false",
        insideBathroom: item.insideAppartment
          ? item.insideAppartment.toString()
          : "false",
        bedroom: item.bedRoomNumber,
      };

      if (item.bedRoomNumber) selectedBathrooms[item.bedRoomNumber] = true;
      return obj;
    });

    setSelectedBathroom(selectedBathrooms);

    propertyFeaturesFormik.setValues({
      bathrooms,
      beds,
      totalBeds: props.property.bedRoomData.length,
      totalBathrooms: props.property.bathRooms.length,
    });

    if (user.userType === USER_TYPE.VENDOR && reloadAPI) {
      props.listBedCategories(props.loader);
      props.listPropertyAmenities(props.loader);
    }

    editOtherRoomFormik.setValues({
      ...props.property.otherRoom,
    });

    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "selectedImage",
      value: props.property.images,
    });
    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "images",
      value: props.property.images,
    });
  };

  const handleAmnitiesChange = (event) => {
    let tempAmnities = [...editAmnitiesFormik.values.amenities];
    const index = tempAmnities.findIndex(
      (item) => item.value === event.target.value
    );

    if (index >= 0) {
      tempAmnities[index].checked = !tempAmnities[index].checked;
      tempAmnities[index].special = false;
      editAmnitiesFormik.setFieldValue("amenities", tempAmnities);
    }
  };

  const handleSpecialAmenitiesChange = (value) => {
    let tempAmnities = [...editAmnitiesFormik.values.amenities];

    let index = tempAmnities.findIndex((item) => item.value === value);

    if (index >= 0) {
      tempAmnities[index].checked = true;
      tempAmnities[index].special = !tempAmnities[index].special;

      editAmnitiesFormik.setFieldValue("amenities", tempAmnities);
    }
  };

  const handlePropertyPriority = (index) => {
    let priorty = recommendedProperties.properties.filter(
      (item) => item.positionNumber
    );
    let tempProperties = recommendedProperties.properties;
    if (!priorty.length) {
      tempProperties[index].positionNumber = 1;
      setRecommendedProperties({
        ...recommendedProperties,
        properties: tempProperties,
      });
    } else {
      if (tempProperties[index].positionNumber) {
        tempProperties[index].positionNumber = null;
        for (let i = 0; i < tempProperties.length; i++) {
          if (
            i >= index &&
            tempProperties[i].positionNumber &&
            tempProperties.length !== index
          ) {
            tempProperties[i].positionNumber -= 1;
          }
        }
      } else {
        priorty.sort((a, b) => a.positionNumber - b.positionNumber);
        tempProperties[index].positionNumber =
          priorty[priorty.length - 1].positionNumber + 1;
      }
      setRecommendedProperties({
        ...recommendedProperties,
        properties: tempProperties,
      });
    }
  };

  const updateRecommended = () => {
    let filteredProperties = recommendedProperties.properties.filter(
      (item) => item.positionNumber
    );
    filteredProperties = filteredProperties.map((item) => ({
      propertyId: item._id,
      positionNumber: item.positionNumber,
    }));
    props.update(
      {
        recommendedProperties: filteredProperties,
        property_id: props.property._id,
      },
      props.loader,
      props.property._id
    );
    props.closeEditPropertyModal();
  };

  const fetchProperties = () => {
    const recommendedPropertiesQuery = {
      propertyId: props.property._id,
      offset: recommendedPropertiesPagination.offset,
      limit: recommendedPropertiesPagination.limit,
    };
    props
      .getRecommendedProperties(recommendedPropertiesQuery, props.loader)
      .then((res) => {
        setRecommendedProperties({
          properties: [...recommendedProperties.properties, ...res.properties],
          count: res.count,
        });
      });
  };

  useEffect(() => {
    if (props.property && props.property._id) {
      fetchProperties();
    }
  }, [props.property, recommendedPropertiesPagination]);

  useEffect(() => {
    if (
      observerTarget.current &&
      props.type === EDIT_PROPERTY_TYPE.RECOMMENDED
    ) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            dispatch({
              type: COMMON_ACTIONS.SET_RECOMMENDED_PROPERTIES_PAGINATION,
            });
          }
        },
        { threshold: 0.23 }
      );

      observer.observe(observerTarget.current);

      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }

    return () => {};
  }, [observerTarget, props.type]);

  useEffect(() => {
    if (Object.keys(props.property).length) {
      setPropertyInputs();
    }
  }, [props.property]);

  useEffect(() => {
    if (propertyAmenities.length) {
      let tempAmenities = propertyAmenities;

      for (const item of tempAmenities) {
        let amenity = props.property.amenities.find(
          (it) => it._id === item.value
        );

        if (amenity) {
          item.checked = true;
          item.special = amenity.isSpecial;
        }
      }

      editAmnitiesFormik.setFieldValue("amenities", tempAmenities);
    }
  }, [propertyAmenities]);

  const handleAreaChange = (event) => {
    editLocationFormik.setFieldValue(event.target.name, event.target.value);

    const specificArea = areas.find((item) => item._id === event.target.value);

    // setDisabledLocations({
    //     ...disabledLocations,
    //     area: specificArea ? specificArea.status === 'INACTIVE' : false,
    //     building: false,
    // });

    if (specificArea) {
      const buildings = specificArea.buildings.map((item) => ({
        _id: item._id,
        name: item.name,
        latitude: item.latitude,
        longitude: item.longitude,
      }));

      setBuildings(buildings);
    } else setBuildings([]);
  };

  const handleCityChange = (event) => {
    editLocationFormik.setFieldValue(event.target.name, event.target.value);

    if (event.target.value && event.target.value.length) {
      editLocationFormik.setFieldError(event.target.name, undefined);
    }

    const specificCity = props.property.cityData.find(
      (item) => item._id === event.target.value
    );

    if (specificCity) {
      const areas = specificCity.areaData.map((item) => ({
        _id: item._id,
        name: item.name,
        buildings: item.buildingData,
      }));

      setAreas(areas);
      setBuildings([]);
      editLocationFormik.setFieldValue("building", "");
      editLocationFormik.setFieldValue("area", "");
    } else {
      setAreas([]);
      setBuildings([]);
    }
  };

  const removeBedrooms = (index) => {
    const bathrooms = propertyFeaturesFormik.values.bathrooms.filter(
      (item) => item.bedroom !== index + 1
    );

    propertyFeaturesFormik.setFieldValue("bathrooms", bathrooms);
    propertyFeaturesFormik.setFieldValue("totalBathrooms", bathrooms.length);
  };

  const closeModal = () => {
    setPropertyInputs(false);

    props.closeEditPropertyModal();
  };

  return (
    <div
      className={`modal fade ${props.show ? "show d-block" : ""}`}
      id="calendar-price"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.property.name}</h5>
            <button
              type="button"
              className={`${styles.closeButton} btn-close`}
              onClick={closeModal}
            ></button>
          </div>
          <div
            className={`modal-body 
                        ${
                          props.type === EDIT_PROPERTY_TYPE.RECOMMENDED
                            ? styles["gallery-modal"]
                            : props.type === EDIT_PROPERTY_TYPE.FEATURES
                            ? styles.featuresBody
                            : props.type === EDIT_PROPERTY_TYPE.AMMENITIES
                            ? styles.amenitiesBody
                            : ""
                        } `}
          >
            {props.type === EDIT_PROPERTY_TYPE.NAME ? (
              <Fragment>
                <div className="px-3 pt-2">
                  <label className="mb-1 d-flex align-items-center">
                    Name
                    {editNameFormik.touched.name &&
                    editNameFormik.errors.name ? (
                      <p className="error my-0 ms-1">
                        {editNameFormik.errors.name}
                      </p>
                    ) : null}
                  </label>
                  <MdEditor
                    plugins={EDITOR_PLUGINS}
                    renderHTML={(text) => mdParser.render(text)}
                    value={editNameFormik.values.name}
                    onChange={({ text }) =>
                      editNameFormik.setFieldValue("name", text)
                    }
                    view={{ menu: true, md: true, html: false }}
                  />
                </div>
                <div className="px-3 pt-2">
                  <label className="mb-1 d-flex align-items-center">
                    Unit Number
                    {editNameFormik.touched.unitNumber &&
                    editNameFormik.errors.unitNumber ? (
                      <p className="error my-0 ms-1">
                        {editNameFormik.errors.unitNumber}
                      </p>
                    ) : null}
                  </label>
                  <MdEditor
                    plugins={EDITOR_PLUGINS}
                    renderHTML={(text) => mdParser.render(text)}
                    onChange={({ text }) =>
                      editNameFormik.setFieldValue("unitNumber", text)
                    }
                    value={editNameFormik.values.unitNumber}
                    view={{ menu: true, md: true, html: false }}
                  />
                </div>
              </Fragment>
            ) : props.type === EDIT_PROPERTY_TYPE.LOCATION ? (
              <Fragment>
                <div className="px-3 pt-2">
                  <label className="mb-1 w-100 d-flex align-items-center">
                    City
                    {editLocationFormik.touched.city &&
                    editLocationFormik.errors.city ? (
                      <p className="error my-0 ms-1">
                        {editLocationFormik.errors.city}
                      </p>
                    ) : null}
                  </label>
                  <div className="position-relative">
                    <select
                      name="city"
                      onChange={handleCityChange}
                      className="w-100 form-control"
                      value={editLocationFormik.values.city}
                    >
                      <option value="">Click to select</option>
                      {cities.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <i
                      className={`fa fa-chevron-down ${styles.selectArrow}`}
                    ></i>
                  </div>
                </div>
                <div className="px-3 pt-2">
                  <label className="mb-1 w-100 d-flex align-items-center">
                    Area
                    {editLocationFormik.touched.area &&
                    editLocationFormik.errors.area ? (
                      <p className="error my-0 ms-1">
                        {editLocationFormik.errors.area}
                      </p>
                    ) : null}
                  </label>
                  <div className="position-relative">
                    <select
                      name="area"
                      value={editLocationFormik.values.area}
                      onChange={handleAreaChange}
                      className="w-100 form-control"
                    >
                      <option value="">Click to select</option>
                      {areas.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <i
                      className={`fa fa-chevron-down ${styles.selectArrow}`}
                    ></i>
                  </div>
                </div>
                <div className="px-3 pt-2">
                  <label className="mb-1 w-100 d-flex align-items-center">
                    Building
                    {editLocationFormik.touched.building &&
                    editLocationFormik.errors.building ? (
                      <p className="error my-0 ms-1">
                        {editLocationFormik.errors.building}
                      </p>
                    ) : null}
                  </label>
                  <div className="position-relative">
                    <select
                      name="building"
                      value={editLocationFormik.values.building}
                      onChange={(e) => {
                        // const building = buildings.find(item => item._id === e.target.value);

                        // setDisabledLocations({
                        //     ...disabledLocations,
                        //     building: building ? building.status === 'INACTIVE' : false,
                        // });
                        editLocationFormik.setFieldValue(
                          e.target.name,
                          e.target.value
                        );
                      }}
                      className="w-100 form-control"
                    >
                      <option value="">Click to select</option>
                      {buildings.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <i
                      className={`fa fa-chevron-down ${styles.selectArrow}`}
                    ></i>
                  </div>
                </div>
              </Fragment>
            ) : props.type === EDIT_PROPERTY_TYPE.DESCRIPTION ? (
              <div className="w-75">
                <div className="form-group description" data-color-mode="light">
                  <label className="mb-1">Enter Description</label>
                  <MdEditor
                    plugins={EDITOR_PLUGINS}
                    className={styles.descriptionEditor}
                    renderHTML={(text) => mdParser.render(text)}
                    value={editDescriptionFormik.values.description}
                    onChange={({ text }) =>
                      editDescriptionFormik.setFieldValue("description", text)
                    }
                    view={{ menu: true, md: true, html: false }}
                  />
                  {editDescriptionFormik.errors.description &&
                  editDescriptionFormik.touched.description ? (
                    <div className="error">
                      {editDescriptionFormik.errors.description}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : props.type === EDIT_PROPERTY_TYPE.FEATURES ? (
              <div className="h-50">
                <FormikProvider value={propertyFeaturesFormik}>
                  <label className="w-100">Total Bedrooms</label>
                  <Field
                    min={1}
                    name="totalBeds"
                    as="select"
                    value={propertyFeaturesFormik.values.totalBeds}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      let newArr = [...propertyFeaturesFormik.values.beds];

                      if (parseInt(value, 10) > newArr.length) {
                        const newLength = parseInt(value, 10) - newArr.length;
                        let maximumBedNumber =
                          propertyFeaturesFormik.values.beds.reduce(
                            (max, obj) =>
                              obj.bedRoomName > max ? obj.bedRoomName : max,
                            propertyFeaturesFormik.values.beds[0].bedRoomName
                          );

                        for (let i = 0; i < newLength; i++) {
                          newArr.push({
                            bedRoomName: maximumBedNumber + 1,
                            items: [{ bedType: "", totalBeds: 0 }],
                          });

                          maximumBedNumber += 1;
                        }
                      } else {
                        let toDelete = newArr.length - parseInt(value, 10);

                        while (toDelete--) {
                          newArr.pop();
                        }
                      }

                      propertyFeaturesFormik.setFieldValue(name, value);
                      propertyFeaturesFormik.setFieldValue("beds", newArr);
                    }}
                  >
                    <option value={0}>Click to select</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Field>
                  {propertyFeaturesFormik.errors.totalBeds &&
                  propertyFeaturesFormik.touched.totalBeds ? (
                    <div className="error">
                      {propertyFeaturesFormik.errors.totalBeds}
                    </div>
                  ) : null}

                  <FieldArray
                    name="beds"
                    render={(arrayHelper) => (
                      <div>
                        {propertyFeaturesFormik.values.beds.map(
                          (bed, index) => (
                            <div
                              className={styles["bathrom-options"]}
                              key={index}
                            >
                              <div>
                                <h6>Bedroom {index + 1}</h6>
                                <FieldArray
                                  name={`beds.${index}.items`}
                                  render={(innerArrayHelper) => (
                                    <div>
                                      {bed.items.map(
                                        (innerItem, innerIndex) => (
                                          <div key={innerIndex}>
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="form-group">
                                                  <label>
                                                    What kind of beds are
                                                    available?
                                                  </label>
                                                  <div>
                                                    <Field
                                                      as="select"
                                                      name={`beds.${index}.items.${innerIndex}.bedType`}
                                                      onChange={(event) => {
                                                        const { name, value } =
                                                          event.target;
                                                        propertyFeaturesFormik.setFieldValue(
                                                          name,
                                                          value
                                                        );
                                                      }}
                                                      value={innerItem.bedType}
                                                      onBlur={() => {}}
                                                    >
                                                      <option value="">
                                                        Select a bed type
                                                      </option>
                                                      {bedCategories.map(
                                                        (item, key) => (
                                                          <option
                                                            key={key}
                                                            value={item._id}
                                                          >
                                                            {item.name}
                                                          </option>
                                                        )
                                                      )}
                                                    </Field>
                                                    <ErrorMessage
                                                      component="div"
                                                      className="error"
                                                      name={`beds.${index}.items.${innerIndex}.bedType`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="form-group">
                                                  <label>Number of beds</label>
                                                  <div>
                                                    <Field
                                                      as="select"
                                                      name={`beds.${index}.items.${innerIndex}.totalBeds`}
                                                      onChange={(event) => {
                                                        const { name, value } =
                                                          event.target;
                                                        propertyFeaturesFormik.setFieldValue(
                                                          name,
                                                          value
                                                        );
                                                      }}
                                                      value={
                                                        innerItem.totalBeds
                                                      }
                                                      onBlur={() => {}}
                                                    >
                                                      <option value={0}>
                                                        Select number of beds
                                                      </option>
                                                      <option value={1}>
                                                        1
                                                      </option>
                                                      <option value={2}>
                                                        2
                                                      </option>
                                                      <option value={3}>
                                                        3
                                                      </option>
                                                    </Field>
                                                    <ErrorMessage
                                                      component="div"
                                                      className="error"
                                                      name={`beds.${index}.items.${innerIndex}.totalBeds`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center my-2">
                                              {bed.items.length ===
                                              innerIndex + 1 ? (
                                                <div className="form-group">
                                                  <span
                                                    className={
                                                      styles["add-onother-nbed"]
                                                    }
                                                    onClick={() =>
                                                      innerArrayHelper.push({
                                                        bedType: "",
                                                        totalBeds: 0,
                                                      })
                                                    }
                                                  >
                                                    <i className="fas fa-plus-circle"></i>
                                                    Add another bed
                                                  </span>
                                                </div>
                                              ) : null}
                                              {propertyFeaturesFormik.values
                                                .beds.length !== 1 ? (
                                                <span
                                                  className={
                                                    styles["add-onother-nbed"]
                                                  }
                                                  onClick={() => {
                                                    removeBedrooms(index);
                                                    arrayHelper.remove(index);
                                                  }}
                                                >
                                                  <i className="fas fa-minus-circle"></i>
                                                  Remove bed
                                                </span>
                                              ) : null}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  />
                  <label className="w-100">Total Bathrooms</label>
                  <Field
                    min={1}
                    name="totalBathrooms"
                    as="select"
                    value={propertyFeaturesFormik.values.totalBathrooms}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      let newArr = [...propertyFeaturesFormik.values.bathrooms];

                      if (parseInt(value, 10) > newArr.length) {
                        const newLength = parseInt(value, 10) - newArr.length;

                        for (let i = 0; i < newLength; i++) {
                          newArr.push({
                            private: null,
                            ensuite: null,
                            bedroom: "",
                            insideBathroom: null,
                          });
                        }
                      } else {
                        let toDelete = newArr.length - parseInt(value, 10);

                        while (toDelete--) {
                          newArr.pop();
                        }
                      }

                      propertyFeaturesFormik.setFieldValue(name, value);
                      propertyFeaturesFormik.setFieldValue("bathrooms", newArr);
                    }}
                  >
                    <option value={0}>Click to select</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Field>
                  {propertyFeaturesFormik.errors.totalBathrooms &&
                  propertyFeaturesFormik.touched.totalBathrooms ? (
                    <div className="error">
                      {propertyFeaturesFormik.errors.totalBathrooms}
                    </div>
                  ) : null}
                  <FieldArray
                    name="bathrooms"
                    render={() => (
                      <div>
                        {propertyFeaturesFormik.values.bathrooms.map(
                          (item, index) => (
                            <div
                              className={styles["bathrom-options"]}
                              key={index}
                            >
                              <div>
                                <h6>Bathroom {index + 1}</h6>
                                <div className="form-group">
                                  <label>
                                    Is the bathroom private ? (not shared with
                                    host or other guests)
                                  </label>
                                  <div>
                                    <span>
                                      <Field
                                        type="radio"
                                        name={`bathrooms.${index}.private`}
                                        value="true"
                                      />{" "}
                                      Yes
                                    </span>
                                    <span>
                                      <Field
                                        type="radio"
                                        name={`bathrooms.${index}.private`}
                                        value="false"
                                        onChange={(event) => {
                                          const { name, value } = event.target;

                                          if (value === "false") {
                                            propertyFeaturesFormik.setFieldValue(
                                              `bathrooms.${index}.ensuite`,
                                              false
                                            );
                                            propertyFeaturesFormik.setFieldValue(
                                              `bathrooms.${index}.bedroom`,
                                              false
                                            );
                                          }

                                          propertyFeaturesFormik.setFieldValue(
                                            name,
                                            value
                                          );
                                        }}
                                      />{" "}
                                      No
                                    </span>
                                  </div>
                                  <ErrorMessage
                                    component="div"
                                    className="error"
                                    name={`bathrooms.${index}.private`}
                                  />
                                </div>
                                {item.private === "true" ? (
                                  <div className="form-group">
                                    <label>
                                      Is the bathroom ensuite ? (attached to or
                                      inside the bedroom)
                                    </label>
                                    <div>
                                      <span>
                                        <Field
                                          type="radio"
                                          name={`bathrooms.${index}.ensuite`}
                                          value="true"
                                        />{" "}
                                        Yes
                                      </span>
                                      <span>
                                        <Field
                                          type="radio"
                                          name={`bathrooms.${index}.ensuite`}
                                          value="false"
                                          onChange={(event) => {
                                            const { name, value } =
                                              event.target;

                                            propertyFeaturesFormik.setFieldValue(
                                              `bathrooms.${index}.insideBathroom`,
                                              ""
                                            );

                                            if (value === "false") {
                                              propertyFeaturesFormik.setFieldValue(
                                                `bathrooms.${index}.bedroom`,
                                                ""
                                              );
                                            }
                                            propertyFeaturesFormik.setFieldValue(
                                              name,
                                              value
                                            );
                                          }}
                                        />{" "}
                                        No
                                      </span>
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      className="error"
                                      name={`bathrooms.${index}.ensuite`}
                                    />
                                  </div>
                                ) : null}
                                {item.ensuite === "true" ? (
                                  <div className="form-group">
                                    <label>
                                      Which bedroom is it attached to?
                                    </label>
                                    <div>
                                      <Field
                                        as="select"
                                        name={`bathrooms.${index}.bedroom`}
                                        onChange={(event) => {
                                          const { name, value } = event.target;

                                          propertyFeaturesFormik.setFieldValue(
                                            name,
                                            value
                                          );
                                          let selectedBathroom = {};
                                          for (const item of propertyFeaturesFormik
                                            .values.bathrooms) {
                                            if (item.bedroom)
                                              selectedBathroom[
                                                item.bedroom
                                              ] = true;
                                          }

                                          setSelectedBathroom({
                                            ...selectedBathroom,
                                            [value]: true,
                                          });
                                        }}
                                      >
                                        <option value={0}>
                                          click to select
                                        </option>
                                        {propertyFeaturesFormik.values.beds.map(
                                          (it, index) => (
                                            <option
                                              key={index + 1}
                                              disabled={
                                                selectedBathroom[
                                                  (index + 1).toString()
                                                ] || false
                                              }
                                              value={index + 1}
                                            >
                                              Bedroom {index + 1}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      className="error"
                                      name={`bathrooms.${index}.bedroom`}
                                    />
                                  </div>
                                ) : null}
                                {item.ensuite === "false" ? (
                                  <div className="form-group">
                                    <label>
                                      Is the bathroom inside the apartment ?
                                    </label>
                                    <div>
                                      <span>
                                        <Field
                                          type="radio"
                                          name={`bathrooms.${index}.insideBathroom`}
                                          value="true"
                                        />{" "}
                                        Yes
                                      </span>
                                      <span>
                                        <Field
                                          type="radio"
                                          name={`bathrooms.${index}.insideBathroom`}
                                          value="false"
                                        />{" "}
                                        No
                                      </span>
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      className="error"
                                      name={`bathrooms.${index}.insideBathroom`}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  />
                </FormikProvider>
              </div>
            ) : props.type === EDIT_PROPERTY_TYPE.AMMENITIES ? (
              <Fragment>
                <div className="mb-1 w-100 d-flex align-items-center">
                  <h3 className="mb-0">Amenities</h3>
                  {editAmnitiesFormik.errors.amenities &&
                  editAmnitiesFormik.touched.amenities ? (
                    <div className="error my-0 ms-1">
                      {editAmnitiesFormik.errors.amenities}
                    </div>
                  ) : null}
                </div>

                <div className="h-50 px-4">
                  {editAmnitiesFormik.values.amenities.map((item, index) => (
                    <div
                      className="d-flex justify-content-between mb-4"
                      key={index}
                    >
                      <p
                        onClick={() => handleSpecialAmenitiesChange(item.value)}
                        className="mb-0 cursor-pointer"
                      >
                        {item.label}
                      </p>
                      <label className={styles.chk}>
                        <input
                          type="checkbox"
                          className={item.special ? "specialAmmenity" : ""}
                          checked={item.checked}
                          value={item.value}
                          onChange={handleAmnitiesChange}
                        />
                        <span
                          className={`${styles.checkmark} ${
                            item.special ? styles.specialCheckmark : ""
                          }`}
                        ></span>
                      </label>
                    </div>
                  ))}
                </div>
              </Fragment>
            ) : props.type === EDIT_PROPERTY_TYPE.IMAGES ? (
              <FormikProvider value={editImageFormik}>
                <DropImage formik={editImageFormik} />
              </FormikProvider>
            ) : props.type === EDIT_PROPERTY_TYPE.OTHER_ROOM ? (
              <FormikProvider value={editOtherRoomFormik}>
                <div className="col-md-12 mb-3">
                  <div className="row">
                    <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                      <label>Driver Room</label>
                      <Switch
                        onColor="#e89532"
                        onChange={(value) =>
                          editOtherRoomFormik.setFieldValue("driverRoom", value)
                        }
                        checked={editOtherRoomFormik.values.driverRoom}
                      />
                    </div>

                    <div
                      className={`col-md-6 form-group d-flex justify-content-between align-items-center 
                                                                    ${
                                                                      !editOtherRoomFormik
                                                                        .values
                                                                        .maidRoom &&
                                                                      !editOtherRoomFormik
                                                                        .values
                                                                        .driverRoom
                                                                        ? "d-none"
                                                                        : ""
                                                                    }`}
                    >
                      {editOtherRoomFormik.values.driverRoom ? (
                        <Fragment>
                          <label>Is Bathroom Attached</label>
                          <Switch
                            onColor="#e89532"
                            onChange={(value) =>
                              editOtherRoomFormik.setFieldValue(
                                "driverRoomBathRoom",
                                value
                              )
                            }
                            checked={
                              editOtherRoomFormik.values.driverRoomBathRoom
                            }
                          />
                        </Fragment>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                      <label>Maid Room</label>
                      <Switch
                        onColor="#e89532"
                        onChange={(value) =>
                          editOtherRoomFormik.setFieldValue("maidRoom", value)
                        }
                        checked={editOtherRoomFormik.values.maidRoom}
                      />
                    </div>

                    <div
                      className={`col-md-6 form-group d-flex justify-content-between align-items-center 
                                                                        ${
                                                                          editOtherRoomFormik
                                                                            .values
                                                                            .maidRoom
                                                                            ? ""
                                                                            : "d-none"
                                                                        }`}
                    >
                      <Fragment>
                        <label>Is Bathroom Attached</label>
                        <Switch
                          onColor="#e89532"
                          onChange={(value) =>
                            editOtherRoomFormik.setFieldValue(
                              "maidRoomBathRoom",
                              value
                            )
                          }
                          checked={editOtherRoomFormik.values.maidRoomBathRoom}
                        />
                      </Fragment>
                    </div>
                  </div>
                </div>
              </FormikProvider>
            ) : props.type === EDIT_PROPERTY_TYPE.RECOMMENDED ? (
              <div className="row h-100">
                {recommendedProperties.properties.map((item, index) => (
                  <div className="col-md-4 position-relative" key={item._id}>
                    <img src={item.image} className="w-100" />
                    <p
                      onClick={() => handlePropertyPriority(index)}
                      className={
                        !item.positionNumber
                          ? styles.galleryCount
                          : styles.selectedGalleryCount
                      }
                    >
                      {item.positionNumber}
                    </p>
                    <div className={styles["slide-name"]}>
                      <h4>{item.name}</h4>
                    </div>
                  </div>
                ))}
                <div ref={observerTarget}></div>
              </div>
            ) : null}
          </div>

          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                if (props.type === EDIT_PROPERTY_TYPE.NAME)
                  editNameFormik.submitForm();
                else if (props.type === EDIT_PROPERTY_TYPE.LOCATION)
                  editLocationFormik.submitForm();
                else if (props.type === EDIT_PROPERTY_TYPE.DESCRIPTION)
                  editDescriptionFormik.submitForm();
                else if (props.type === EDIT_PROPERTY_TYPE.FEATURES)
                  propertyFeaturesFormik.submitForm();
                else if (props.type === EDIT_PROPERTY_TYPE.AMMENITIES)
                  editAmnitiesFormik.submitForm();
                else if (props.type === EDIT_PROPERTY_TYPE.IMAGES)
                  editImageFormik.submitForm();
                else if (props.type === EDIT_PROPERTY_TYPE.OTHER_ROOM)
                  editOtherRoomFormik.submitForm();
                else if (props.type === EDIT_PROPERTY_TYPE.RECOMMENDED)
                  updateRecommended();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (payload, loader, id) =>
    dispatch(VendorPropertyAction.update(payload, loader, id)),
  listBedCategories: (loader) => dispatch(AdminPropertyAction.listBeds(loader)),
  listPropertyAmenities: (loader) =>
    dispatch(AdminPropertyAction.listAmenities(loader)),
  getRecommendedProperties: (query, loader) =>
    dispatch(CommonAction.recommendedProperties(query, loader)),
});

export default connect(null, mapDispatchToProps)(EditProperty);
